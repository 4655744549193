import TicketInfo from './scan/ticketInfo';
import RecoveryTicket from './scan/recoveryTicket';
import Info from './infoScene';
import Error from './error';
import Confirm from './confirm';
import Print from './print/print';
import Scan from './scan/scan';
import PreEntry from './preEntry/preEntry';
import PinCode from './preEntry/pincode';
import PreRecord from './preEntry/record';
import ScanPolicy from './preEntry/scanPolicy';
import EmulatePreRecord from './emulatePreRecord';
import NewPreRecord from './newPreRecord/NewPreRecord';

export const Main = [
  { name: '/infoScene/', component: Info },
  { name: '/recoveryTicket/:url', component: RecoveryTicket },
  { name: '/ticketInfo/', component: TicketInfo },
  { name: '/error', component: Error },
  { name: '/confirm', component: Confirm },
  { name: '/print', component: Print },
  { name: '/scan', component: Scan, buttons: true },
  { name: '/preEntry/', component: PreEntry, buttons: true },
  {
    name: '/newPreEntry/', component: PreEntry, buttons: true, newPreEntry: true,
  },
  { name: '/pinCode', component: PinCode, buttons: true },
  { name: '/preRecord', component: PreRecord, buttons: true },
  { name: '/newPreRecord', component: NewPreRecord, buttons: true },
  { name: '/scanPolicy', component: ScanPolicy, buttons: true },
  { name: '/emulatePreRecord/', component: EmulatePreRecord, buttons: true },
];

export default null;
