import moment from 'moment';

export default function TableConfig(getColumnSearchProps) {
  function hhss(cell, row, key) {
    return moment.utc(row[key] * 1000).format('HH:mm:ss');
  }

  function status(cell) {
    switch (cell) {
      case 0: return 'Создан и находится в изначальной очереди';
      case 1: return 'Вызван на обработку';
      case 2: return 'В процессе обработки';
      case 5: return 'Переведён в новую очередь';
      case 6: return 'Ожидание решения';
      case 7: return 'Возвращён в очередь';
      case 8: return 'Ручная обработка диспетчером';
      case 9: return 'Удален из-за наступления нового дня (талон по какой-то причине просуществовал до ночи в системе)';
      case 11: return 'Переведен на рабочее место';
      default: return null;
    }
  }

  return {
    columns: [
      {
        dataIndex: 'number',
        title: 'Номер',
        ...getColumnSearchProps('number', 'номер'),
      },
      {
        dataIndex: 'queueName',
        title: 'Наименование очереди',
        ...getColumnSearchProps('queueName', 'наименование очереди'),
      },
      {
        dataIndex: 'status',
        title: 'Статус',
        render: status,
        filters: [
          {
            text: 'Создан и находится в изначальной очереди',
            value: 0,
          },
          {
            text: 'Вызван на обработку',
            value: 1,
          },
          {
            text: 'В процессе обработки',
            value: 2,
          },
          {
            text: 'Переведён в новую очередь',
            value: 5,
          },
          {
            text: 'Ожидание решения',
            value: 6,
          },
          {
            text: 'Возвращён в очередь',
            value: 7,
          },
          {
            text: 'Ручная обработка диспетчером',
            value: 8,
          },
          {
            text: 'Удален из-за наступления нового дня (талон по какой-то причине просуществовал до ночи в системе)',
            value: 9,
          },
          {
            text: 'Переведен на рабочее место',
            value: 11,
          },
          {
            text: 'Начато решение вопроса',
            value: 12,
          },
          {
            text: 'Завершено решение вопроса',
            value: 13,
          },
        ],

        onFilter: (value, record) => record.status === value,
      },
      {
        dataIndex: 'waitingTime', // 'totalwait' all 0 in response
        title: 'Время ожидания',
        render: (cell, row) => hhss(cell, row, 'totalwait'),
        sorter: (a, b) => a.totalwait - b.totalwait,
      },
      {
        dataIndex: 'processTime', // 'procforsecs' all 0 in response
        title: 'Время обслуживания',
        render: (cell, row) => hhss(cell, row, 'procforsecs'),
        sorter: (a, b) => a.procforsecs - b.procforsecs,
      },
      {
        dataIndex: 'createdOn',
        title: 'Время создания',
        sorter: {
          compare: (a, b) => moment(a.createdOn, "YYYY.MM.DD HH:mm:ss'") - moment(b.createdOn, 'YYYY.MM.DD HH:mm:ss'),
        },
        width: 200,
      },
      {
        dataIndex: 'first_start_time',
        title: 'Время вызова',
        render: (cell) => {
          if (cell === '01.01.2000 01:01:01') return '';
          return cell && (moment(cell, 'YYYY.MM.DD HH:mm:ss').format('DD.MM.YYYY HH:mm:ss'));
        },
        sorter: {
          compare: (a, b) => moment(a.first_start_time, "YYYY.MM.DD HH:mm:ss'") - moment(b.first_start_time, 'YYYY.MM.DD HH:mm:ss'),
        },
        width: 200,
      },
    ],
  };
}
