import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PolicyContainer, PoliceUl } from '../../../../../utils/style';
import changeStyle from '../../../../common/changeStyle';
import PoliceButton from './policyButton';
import PolicyBB from './policyBB';
import { setTicket } from '../../../../../../store/actions';
import scanPolicyStore from '../../../../../../../../../logic/store/pullstate/scanPolicy';

function PolicyList({
  startScreen, button, title,
}) {
  const records = scanPolicyStore.useState((s) => s.records);
  const navigate = useNavigate();

  const { headUrl, id } = useSelector((state) => state.config);
  const styleButton = changeStyle({ style: button, item: { size: 'md' } });
  const dispatch = useDispatch();
  const style = {
    fontSize: title.fontSize * 0.4,
    fontWeight: 'normal',
    textTransform: 'capitalize',
    marginBottom: title.marginBottom * 0.2,
  };

  const styleBottom = {
    ...style,
    background: button.background,
  };

  const createTicket = (el) => {
    dispatch(setTicket({ name: 'staffId', value: el.staff_id }));
    dispatch(setTicket({ name: 'cabinet', value: el.cabinet }));
    dispatch(setTicket({ name: 'appointmentId', value: el.appointment_id }));
    navigate(`${headUrl}/${id}/print`);
  };

  const policeItem = (el) => {
    const doctorName = `${el.staff_last_name} ${el.staff_first_name} ${el.staff_middle_name}`;
    const { cabinet } = el;

    return (
      <li key={el.appointment_id} style={{ padding: '10px 25px' }}>
        <div className="policeItemText">
          <h6 style={style}>{doctorName}</h6>
          <p style={styleBottom}>
            Кабинет:
            {' '}
            {cabinet}
          </p>
          <div className="policeListBottom">
            <p className="time" style={styleBottom}>
              начало приема:
              {el.begin_time}
            </p>
          </div>
        </div>
        <PoliceButton
          style={{ ...styleButton, alineSelf: 'center' }}
          el={{ text: 'Получить талон', link: startScreen }}
          handeleClick={() => {
            createTicket(
              el,
            );
          }}
        />
      </li>
    );
  };

  return (
    <PolicyContainer>
      <div className="policyBox">
        {records !== null && records.length > 0
          ? (
            <PoliceUl maxWidth={button.widthContent}>
              {records[0].appointment.map((el) => policeItem(el))}
            </PoliceUl>
          )
          : <p style={{ ...title, fontSize: title.fontSize * 0.8 }}>Записей нет</p>}
      </div>
      <PolicyBB button={button} startScreen={startScreen} />
    </PolicyContainer>
  );
}

export default PolicyList;
