import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import {
  Button, Calendar, Card, Col, Collapse, Drawer, Row, Skeleton, Statistic,
} from 'antd';
import preorderRest from '../../../../../utils/preorderRest';
import SlotCard from '../../../../../components/preorder/slotCard';
import CalendarHeader from '../../../../../components/preorder/calendarHeader';
import CreateSlot from '../../../../../components/preorder/createSlot';
import CopySlot from '../../../../../components/preorder/copySlot';
import DeleteSlot from '../../../../../components/preorder/deleteSlot';
import RewriteSlot from '../../../../../components/preorder/rewriteSlot';
import './preorder.css';

function PreEntry() {
  const [openDrawer, setOpenDrawer] = useState(undefined);
  const [dates, setDates] = useState([dayjs().startOf('month').format('YYYY-MM-DD HH:mm:ss'), dayjs().endOf('month').format('YYYY-MM-DD HH:mm:ss')]);
  const [currentYear, setCurrentYear] = useState(dayjs().year());
  const [currentSlots, setCurrentSlots] = useState();
  const [selectedDate, setSelectedDate] = useState({
    date: dayjs(),
    dayId: 0,
  });
  const [queues, setQueues] = useState();

  const drawerHeaders = {
    slots: `Слоты на ${selectedDate.date.format('DD.MM.YYYY')}`,
    create: 'Добавление слотов',
    copy: `Копирование слотов за ${selectedDate.date.format('DD.MM.YYYY')}`,
    delete: `Удаление слотов за ${selectedDate.date.format('DD.MM.YYYY')}`,
    rewrite: `Перезапись слотов на ${selectedDate.date.format('DD.MM.YYYY')}`,
  };

  const { data: queueData } = useQuery({
    queryKey: 'queues',
    queryFn: preorderRest.getQueue,
    refetchOnWindowFocus: false,
  });

  const { data: daysData } = useQuery({
    queryKey: ['days', currentYear],
    queryFn: () => preorderRest.getDays(currentYear),
    refetchOnWindowFocus: false,
  });

  const {
    data: slotsData,
    isLoading: isSlotsLoading,
    isFetching,
    refetch: refetchSlots,
  } = useQuery({
    queryKey: ['slots', dates],
    queryFn: () => preorderRest.getSlots(dates),
    enabled: !!queueData,
    refetchOnWindowFocus: false,
  });

  const selectDay = (date) => {
    const currentDay = daysData?.find((el) => (
      el.dayOfMonth === date.date() && el.monthNumber === (date.month() + 1)
    ));
    setSelectedDate({ date, dayId: currentDay?.dayId || 0 });
  };

  const selectTime = (el) => {
    setCurrentSlots(el);
    setOpenDrawer('slots');
  };

  const changeMonth = (date) => {
    selectDay(date);
    setCurrentYear(date.year());
    setDates([date.startOf('month').format('YYYY-MM-DD HH:mm:ss'), date.endOf('month').format('YYYY-MM-DD HH:mm:ss')]);
  };

  const closeDrawer = () => {
    setCurrentSlots(undefined);
    setOpenDrawer(undefined);
  };

  const renderSlots = (queue) => {
    const daySlots = queue.slots.filter(
      (slot) => dayjs(slot.thedate).format('YYYY.MM.DD') === selectedDate.date.format('YYYY.MM.DD'),
    );
    const slotsObj = {};
    let all = 0;
    let occupied = 0;
    let free = 0;
    daySlots.forEach((slot) => {
      all += 1;
      if (slot.registered) {
        occupied += 1;
      } else {
        free += 1;
      }
      const idx = Object.keys(slotsObj).findIndex(
        (el) => el === dayjs(slot.pastart_on).format('HH:mm'),
      );
      if (idx === -1) {
        slotsObj[dayjs(slot.pastart_on).format('HH:mm')] = [slot];
      } else {
        slotsObj[dayjs(slot.pastart_on).format('HH:mm')].push(slot);
      }
    });
    return (
      <Collapse.Panel
        header={`${queue.qFullName} (записано: ${occupied} / свободно: ${free} / всего: ${all})`}
        key={queue.qId}
      >
        <div className="preorder__queue__info">
          {(
            Object.keys(slotsObj).map((el) => (
              <Card
                key={el}
                title={el}
                extra={<Button type="dashed" onClick={() => selectTime(slotsObj[el])}>Показать</Button>}
                style={{ width: '240px' }}
              >
                <Row gutter={16}>
                  <Col span={12}>
                    <Statistic
                      title="Всего"
                      value={slotsObj[el].length}
                    />
                  </Col>
                  <Col span={12}>
                    <Statistic
                      title="Свободно"
                      value={slotsObj[el].filter((slot) => !slot.registered).length}
                    />
                  </Col>
                </Row>
              </Card>
            )))}
        </div>
      </Collapse.Panel>
    );
  };

  const cellRender = (date) => {
    const cell = document.querySelector(`[title="${date.format('YYYY-MM-DD')}"]`);
    cell?.classList.remove('free__cell', 'busy__slot');
    const dateSlots = slotsData.filter((slot) => {
      const idx = queues?.findIndex((queue) => (
        queue.qId === slot.q_id
      ));
      return dayjs(slot.thedate).format('YYYY.MM.DD') === date.format('YYYY.MM.DD') && idx !== -1;
    });
    if (!slotsData || selectedDate.date.month() !== date.month()) {
      return '';
    }
    const past = dayjs().startOf('day').isAfter(date);
    const all = dateSlots?.length || 0;
    const free = dateSlots?.filter((slot) => !slot.registered).length || 0;
    const registered = dateSlots?.filter((slot) => slot.registered).length || 0;
    if (free > 0 && !past) {
      cell?.classList.add('free__cell');
    } else if (all > 0 && free === 0 && !past) {
      cell?.classList.add('busy__slot');
    }
    return (
      <div className="preorder__calendar__cell">
        <p className="preorder__calendar__cell__text">
          Всего:
          {' '}
          {all}
        </p>
        <p className="preorder__calendar__cell__text">
          Свободно:
          {' '}
          {free}
        </p>
        <p className="preorder__calendar__cell__text">
          Занято:
          {' '}
          {registered}
        </p>
      </div>
    );
  };

  const renderDrawer = () => {
    switch (openDrawer) {
      case 'slots':
        return (
          <div className="preorder__slot__box">
            {queueData && currentSlots?.map((slot) => (
              <SlotCard
                key={slot.pincode}
                slot={slot}
                closeDrawer={closeDrawer}
                refetchSlots={refetchSlots}
                queues={queues}
              />
            ))}
          </div>
        );
      case 'create':
        return (
          <CreateSlot
            days={daysData}
            queues={queues}
            refetch={refetchSlots}
            closeDrawer={closeDrawer}
            setCurrentYear={setCurrentYear}
          />
        );
      case 'copy':
        return (
          <CopySlot
            queues={queues}
            dayId={selectedDate.dayId}
            refetch={refetchSlots}
            closeDrawer={closeDrawer}
          />
        );
      case 'delete':
        return (
          <DeleteSlot
            queues={queues}
            dayId={selectedDate.dayId}
            refetch={refetchSlots}
            closeDrawer={closeDrawer}
          />
        );
      case 'rewrite':
        return (
          <RewriteSlot
            queues={queues}
            dayId={selectedDate.dayId}
            refetch={refetchSlots}
            closeDrawer={closeDrawer}
          />
        );
      default:
        return '';
    }
  };

  useEffect(() => {
    if (slotsData) {
      const qData = [...queueData];
      queueData.forEach((queue) => {
        const qSlots = slotsData.filter((el) => el.q_id === queue.qId);
        queue.slots = [...qSlots];
      });
      qData.unshift({
        qId: 0,
        qFullName: 'Универсальные слоты для записи',
        slots: [...slotsData.filter((el) => el.q_id === 0)],
      });
      setQueues(qData);
      if (currentSlots) {
        const newSlots = slotsData.filter((slot) => {
          const idx = currentSlots.findIndex((el) => el.calendarCellId === slot.calendarCellId);
          return idx !== -1;
        });
        setCurrentSlots(newSlots);
      }
    }
  }, [isSlotsLoading, isFetching]);

  return (
    <div className="preorder">
      <Drawer
        open={!!openDrawer}
        onClose={closeDrawer}
        title={drawerHeaders[String(openDrawer)]}
        width="auto"
      >
        {!isSlotsLoading && renderDrawer()}
      </Drawer>
      <div className="preorder__calendar">
        <Calendar
          style={{ padding: '10px' }}
          onSelect={selectDay}
          dateCellRender={!isSlotsLoading && queues && cellRender}
          onPanelChange={changeMonth}
          mode="month"
            // eslint-disable-next-line react/no-unstable-nested-components
          headerRender={(data) => <CalendarHeader data={data} />}
        />
      </div>
      <div className="preorder__info">
        <div className="preorder__select__box">
          <Button
            onClick={() => setOpenDrawer('create')}
          >
            Добавить слот
          </Button>
          <Button
            onClick={() => setOpenDrawer('copy')}
          >
            Копировать слоты
          </Button>
          <Button
            onClick={() => setOpenDrawer('delete')}
          >
            Удалить слоты
          </Button>
          <Button
            onClick={() => setOpenDrawer('rewrite')}
          >
            Перезаписать слот
          </Button>
        </div>
        {!queues && <Skeleton active />}
        {queues && (
        <Collapse>
          {queues.filter((el) => {
            const idx = el.slots.findIndex(
              (slot) => dayjs(slot.thedate).format('YYYY.MM.DD') === selectedDate.date.format('YYYY.MM.DD'),
            );
            return idx !== -1;
          }).map((queue) => renderSlots(queue))}
        </Collapse>
        )}
      </div>
    </div>
  );
}

export default PreEntry;
