import React, { useState, useEffect, useCallback } from 'react';
import Modal from '../../../../../../common/ui/Modal/Modal';
import request from '../../../../../../logic/utils/request';
import ParamsItems from './ParamsItems';

function ServiceParams({
  params, setParams, serviceId, preloader, write,
}) {
  const [newParams, setNewParams] = useState([]);
  const [defParams, setDefParams] = useState([]);

  const fetchNewParams = useCallback(() => {
    request('/server/api/officeparamsbinding/', 'get', { q_id: serviceId })
      .then((resp) => {
        setNewParams(resp.data.data);
      });
  }, [serviceId]);

  const fetchDefParams = useCallback(() => {
    preloader();
    request('/server/api/officeparams/', 'get')
      .then((res) => res.data.data.filter((item) => item.applicability.indexOf('queue') >= 0 || null))
      .then((defaultParams) => {
        setDefParams(defaultParams);
        preloader();
      });
  }, [preloader]);

  useEffect(() => {
    if (params) {
      fetchDefParams();
      fetchNewParams();
    }
  }, [params, fetchDefParams, fetchNewParams]);

  console.log('defParams === ', defParams);
  return (
    <Modal title="Параметры услуги" toggleOpen={setParams} isOpen={params}>
      {defParams.length
          && (
            <ParamsItems
              write={write}
              defParams={defParams}
              newParams={newParams}
              serviceId={serviceId}
              fetchNewParams={fetchNewParams}
              fetchDefParams={fetchDefParams}
            />
          )}
    </Modal>
  );
}

export default React.memo(ServiceParams);
