import React from 'react';
import moment from 'moment';
import { Button } from 'antd';

const TableConfig = (userKick, getColumnSearchProps) => {
  function status(cell) {
    let text;
    switch (cell) {
      // case 0 unused
      case 1: text = 'Оператор ожидает';
        break;
      case 2: text = 'Оператор вызвал талон'; // or 'В процессе обработки'
        break;
      case 3: text = 'Обслуживает талон';
        break;
      case 4: text = 'Пауза';
        break;
      case 5: text = 'Начальник смены';
        break;
      default: break;
    }
    return text;
  }
  const login = window.localStorage.getItem('login');

  // 1: 'Оператор залогинен'
  // 2: 'Оператор вызвал талон'
  // 3: 'Обслуживает талон'
  // 4: 'Пауза'
  // 5: 'Начальник смены'

  // const statusType = {
  //   0: 'Рабочее место не занято',
  //   1: 'Оператор залогинен',
  //   2: 'Оператор вызвал талон',
  //   3: 'Обслуживает талон',
  //   4: 'Пауза',
  //   5: 'Начальник смены',
  //   6: 'Подготовка к отключению',
  //   7: 'Отключено',
  // };
  const columns = [
    {
      dataIndex: 'id',
      title: '№/ id',
      ...getColumnSearchProps('id', 'номер/id очереди'),
    },
    {
      dataIndex: 'wplaceName',
      title: 'Наименование',
      ...getColumnSearchProps('wplaceName', 'Наименование'),
      sorter: (a, b) => a.wplaceName - b.wplaceName,
    },
    {
      dataIndex: 'userLogin',
      title: 'Логин',
      ...getColumnSearchProps('userLogin', 'Логин'),
    },
    {
      dataIndex: 'userFIO',
      title: 'ФИО',
      ...getColumnSearchProps('userFIO', 'ФИО'),
    },
    {
      dataIndex: 'ticketNumber',
      title: 'Номер талона',
      ...getColumnSearchProps('ticketNumber', 'Номер талона'),
    },
    {
      dataIndex: 'status',
      title: 'Статус',
      render: status,
      filters: [
        // value 0 unused
        {
          text: 'Оператор ожидает',
          value: 1,
        },
        {
          text: 'Оператор вызвал талон', // or 'В процессе обработки'
          value: 2,
        },
        {
          text: 'Обслуживает талон',
          value: 3,
        },
        {
          text: 'Пауза',
          value: 4,
        },
        {
          text: 'Начальник смены',
          value: 5,
        },
      ],
      onFilter: (value, record) => record.status === value,
    },
    {
      dataIndex: 'ticketModifiedOn',
      title: 'Время простоя оператора',
      render: ((cel, row) => {
        const { idle_since: time } = row;
        let totalTime = '';
        if (time) {
          const CurrentDate = moment().format('YYYY.MM.DD HH:mm:ss');
          totalTime = moment.utc(moment(CurrentDate, 'YYYY.MM.DD HH:mm:ss').diff(moment(time, 'YYYY.MM.DD HH:mm:ss'))).format('HH:mm:ss');
        }
        return (totalTime);
      }),
      sorter: (a, b) => {
        const CurrentDate = moment().format('YYYY.MM.DD HH:mm:ss');

        const aAsSec = new Date(moment(CurrentDate, 'YYYY.MM.DD HH:mm:ss').diff(moment(a.idle_since, 'YYYY.MM.DD HH:mm:ss'))).getTime();
        const bAsSec = new Date(moment(CurrentDate, 'YYYY.MM.DD HH:mm:ss').diff(moment(b.idle_since, 'YYYY.MM.DD HH:mm:ss'))).getTime();

        return (aAsSec - bAsSec);
      },
    },
  ];

  if (login === 'Root') {
    columns.push({
      title: 'Выгнать',
      key: 'operation',
      fixed: 'right',
      width: 100,
      render: (_, row) => <Button onClick={() => userKick(row)}>Выгнать</Button>,
    });
  }

  return (columns);
};
export default TableConfig;
