import React from 'react';
import moment from 'moment';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { MountBox, FilterMonth } from '../../../../utils/style';

function Month({
  month,
  year,
  currentDay,
  selectedYear,
  setDay,
  selectedDay,
  setMonth,
  currentMonth,
  setSelectedYear,
  calendar,
  currentYear,
  selectDay,
  newPreEntrty,
}) {
  console.log('selectedYear === ', selectedYear);
  console.log('111111111 === ', year);
  const newCurrentDay = moment(`${selectedYear}.${month}.${currentDay}`, 'YYYY:MM:DD').isValid() ? currentDay : 1;
  const mom = moment(`${selectedYear}.${month}.${newCurrentDay}`, 'YYYY:MM:DD');
  const offset = moment(`${selectedYear}.${month}.01`, 'YYYY:MM:DD').isoWeekday();
  const countDays = mom.daysInMonth();
  const monthName = mom.format('MMMM');
  const arrDays = [];
  const arrNameDays = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];

  const changeDay = (isOpen, m, d) => {
    if (isOpen) {
      if (newPreEntrty) selectDay(year[m][d].id);
      else setDay(year[m][d].id);
    }
  };

  for (let i = 1; i < offset; i += 1) {
    arrDays.push(<li key={i + 100} />);
  }
  for (let b = 1; b <= countDays; b += 1) {
    const m = moment(month, 'M').format('MM');
    const d = moment(`${selectedYear}.${month}.${b}`, 'YYYY:MM:D').format('DD');
    console.log('year === ', year);
    const isOpen = year[m] && year[m][d] ? year[m][d].count : false;

    arrDays.push(<li
      key={b}
      onClick={() => changeDay(isOpen, m, d)}
      className={`item ${selectedDay === b ? 'active' : ''} ${isOpen ? 'open' : ''}`}
    >
      {b}
      {/* eslint-disable */}
    </li>);
  }

  const changeMonth = (number) => {
    if (+month + number === 13) {
      setSelectedYear(selectedYear + 1);
      setMonth(1);
    } else if (+month + number === 0) {
      setSelectedYear(selectedYear - 1);
      setMonth(12);
    } else {
      setMonth(+month + number);
    }
  };

  const isPrev = !(+month <= +currentMonth && selectedYear === currentYear);
  const isNext = !(+month + 1 === 13 && !calendar[selectedYear + 1]);

  return (
    <>
      <h3>Выберите день</h3>
      <MountBox>
        <FilterMonth className="center">
          <div className="filter">
            {isPrev ? <span onClick={() => changeMonth(-1)}><FaChevronLeft /></span> : <span className="shadow" />}
            <p>{monthName}</p>
            {isNext ? <span onClick={() => changeMonth(1)}><FaChevronRight /></span> : <span className="shadow" />}
          </div>
        </FilterMonth>
        {arrNameDays.map((el) => <li key={el} className="title">{el}</li>)}
        {arrDays}
      </MountBox>
    </>
  );
}

export default Month;
