import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import md5 from 'md5';
import { useNavigate } from 'react-router-dom';
import {
  Skeleton,
} from 'antd';
import getYear from '../../../../../../../logic/utils/getYear';
import Month from '../preEntry/record/Month';
import SelectTime from '../preEntry/record/Time';
import InnerData from '../preEntry/record/InnerData';
import request from '../request';
import { setPre } from '../../../../store/actions';
import './index.css';
import { QueueBox } from '../../../utils/style';
import ChooseQueue from './ChooseQueue';

function NewPreRecord({ children }) {
  const dispatch = useDispatch();
  const { bottomButton } = useSelector((state) => ({
    bottomButton: state.style.bottomButton,
    config: state.config,
    style: state.style,
  }));

  const navigate = useNavigate();
  const [currentScreen, setCurrentScreen] = useState();

  const currentYear = moment().format('YYYY');
  const currentDay = moment().format('DD');
  const currentMonth = moment().format('MM');

  const [selectedQueue, setSelectedQueue] = useState({ id: null });
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [selectedDay, setSelectedDay] = useState(undefined);
  const [selectedTime, setSelectedTime] = useState(undefined);
  const [loaded, setLoaded] = useState(false);
  const [cellId, setCellId] = useState(false);
  const [year, setYear] = useState(undefined);
  const [calendar, setCalendar] = useState(undefined);
  const [times, setTimes] = useState([]);

  const [preEntryActive, setPreEntryActive] = useState(true);

  const [queueList, setQueueList] = useState([]);

  const [preEntryError, setPreEntyError] = useState(false);

  //  const { preEntryInput } = style.general;

  const createTicket = async (contactData) => {
    const data = {
      calendarCellId: cellId,
      clientEmail: contactData.clientEmail || '',
      clientPhoneNumber: contactData.clientPhoneNumber ? `+7${contactData.clientPhoneNumber}` : '',
      clientFIO: contactData.clientFIO || '',
      q_id: selectedQueue.id,
    };

    const ordered = {};
    Object.keys(data).sort().forEach((key) => {
      ordered[key] = data[key];
    });

    let hashString = '';
    Object.keys(ordered).forEach((el) => {
      hashString += data[el];
    });
    data.sign = md5(hashString);

    const answer = await request('/server/public/preorder/register', 'no', { data });
    let d = 0;
    const m = year[String(selectedMonth).padStart(2, '0')];
    Object.keys(m).forEach((el) => { if (m[el].id === selectedDay) d = el; });
    const date = `${d}.${String(selectedMonth).padStart(2, '0')}.${selectedYear} ${selectedTime}`;

    dispatch(setPre({
      pin: answer.data.data.pinCode,
      date,
      service: selectedQueue.name,
    }));

    navigate('../print');
  };

  const fetchQueue = async () => {
    setLoaded(true);
    const { data: { data } } = await request('/server/public/preorder/queues/', 'get');

    if (data.length > 0) {
      getYear('0', '2024')
        .then((res) => {
          if (res?.data?.status === 'failed') {
            setPreEntyError(true);
          } else {
            console.log('res === ', res.selectedYear);
            if (res.selectedYear) {
              setCalendar(res.year);
              setYear(res.selectedYear);
              setCurrentScreen('chooseDay');
            } else {
              setPreEntyError(true);
            }
          }
        });
      setQueueList(data);
      setLoaded(false);
    } else {
      setPreEntryActive(true);
      setLoaded(false);
    }
  };

  useEffect(() => {
    fetchQueue();
  }, []);

  const selectDay = async (dayId) => {
    const answer = await request(`/server/public/preorder/day/${dayId}?queueId=0`);
    setTimes(answer.data.data);
    setSelectedDay(dayId);
    setCurrentScreen('chooseTime');
  };

  const selectTime = (newCellId, time) => {
    setCellId(newCellId);
    setSelectedTime(time);
    setCurrentScreen('chooseQueue');
  };

  const chooseQueue = (obj) => {
    setSelectedQueue(obj);
    setCurrentScreen('clientInfo');
  };

  const renderScreen = () => {
    const screens = {
      chooseDay: <Month
        newPreEntrty
        currentDay={currentDay}
        currentYear={currentYear}
        month={selectedMonth}
        setMonth={setSelectedMonth}
        setDay={setSelectedDay}
        selectDay={selectDay}
        year={year}
        style={bottomButton}
        setQueue={setSelectedQueue}
        selectedYear={selectedYear}
        setSelectedYear={setSelectedYear}
        currentMonth={currentMonth}
        selectedDay={selectedDay}
        calendar={calendar}
      />,
      chooseTime: <SelectTime
        createTicket={createTicket}
        style={bottomButton}
        setDay={setSelectedDay}
        times={times}
        id={cellId}
        // preEntryInput={preEntryInput}
        setId={(newCellId, time) => {
          setCellId(newCellId);
          setSelectedTime(time);
        }}
        newPreEntrty
        selectTime={selectTime}
      />,
      chooseQueue: <ChooseQueue
        queueList={queueList}
        chooseQueue={chooseQueue}
      />,
      clientInfo: <InnerData
        bottomStyle={bottomButton}
        createTicket={createTicket}
      />,
    };

    return screens[currentScreen];
  };

  return (
    <>

      {preEntryError ? (
        <QueueBox>
          <div style={{
            display: 'flex',
            margin: '0 auto',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          >
            Нет слотов для предварительной записи
          </div>
        </QueueBox>
      )
        : (
          <QueueBox>
            {loaded
         && (
         <div style={{
           display: 'flex',
           flexDirection: 'column',
           gap: '20px',
           width: '1080px',
           margin: '0 auto',
         }}
         >
           <Skeleton.Input active size="large" block />
           <Skeleton.Input active size="large" block />
           <Skeleton.Input active size="large" block />
           <Skeleton.Input active size="large" block />
           <Skeleton.Input active size="large" block />
           <Skeleton.Input active size="large" block />
         </div>
         )}
            {preEntryActive ? (renderScreen())
              : (
                <div style={{
                  display: 'flex',
                  margin: '0 auto',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                >
                  Нет доступных очередь для записи. Обратитесь к администратору.
                </div>
              )}

          </QueueBox>
        )}
      {children}
    </>

  );
}

export default NewPreRecord;
